




























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  readResults,
  readResultsPagination,
  readParentCompanies,
  readOrderId,
  readSampleId,
  readBatchId,
  readTestId,
  readTestIds,
  readTestIdsPagination,
  readSampleIds,
  readSampleIdsPagination,
  readOrderIds,
  readOrderIdsPagination,
  readBatchIds,
  readBatchIdsPagination,
  readProductType,
  readProductTypes,
  readProductTypesPagination,
  readReportedDates,
  readSampleName,
  readSampleNames,
  readSampleNamesPagination,
  readTestType,
  readTestTypes,
  readTestTypesPagination,
  readHasAdminAccess,
  readOptions,
} from "@/store/main/getters";
import {
  readAdminParentCompany,
  readAdminParentCompanies,
  readAdminParentCompaniesPagination,
  readAdminChildCompany,
  readAdminChildCompanies,
  readAdminChildCompaniesPagination,
  readShowAdminView,
} from "@/store/admin/getters";
import { debounce } from "@/utils";
import {
  dispatchGetResults,
  dispatchGetParentCompanies,
  dispatchGetResultTestIds,
  dispatchGetResultSampleIds,
  dispatchGetResultOrderIds,
  dispatchGetResultBatchIds,
  dispatchGetResultProductTypes,
  dispatchGetResultSampleNames,
  dispatchGetAllTestTypes,
  dispatchGetCompanyInfo,
} from "@/store/main/actions";
import {
  dispatchGetAdminParentCompanies,
  dispatchGetAdminChildCompanies,
} from "@/store/admin/actions";
import {
  commitSetOrderId,
  commitSetSampleId,
  commitSetBatchId,
  commitSetTestId,
  commitSetProductType,
  commitSetReportedDates,
  commitSetSampleName,
  commitSetTestType,
  commitSetOptions,
} from "@/store/main/mutations";
import {
  commitSetAdminParentCompany,
  commitSetAdminChildCompany,
} from "@/store/admin/mutations";
import AutocompletePaginated from "@/components/AutocompletePaginated.vue";
import AutocompletePaginatedObjects from "@/components/AutocompletePaginatedObjects.vue";
import NavBar from "@/components/NavBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import { formatDate } from "@/date-utils";
import { ITestType, IParentCompany, ICompany, ICompanyInfo } from "@/interfaces";

const debouncedDispatchGetResults = debounce(dispatchGetResults, 300);
const debounceDispatchGetResultTestIds = debounce(dispatchGetResultTestIds, 300);
const debounceDispatchGetResultSampleIds = debounce(dispatchGetResultSampleIds, 300);
const debounceDispatchGetResultOrderIds = debounce(dispatchGetResultOrderIds, 300);
const debounceDispatchGetResultBatchIds = debounce(dispatchGetResultBatchIds, 300);
const debounceDispatchGetResultProductTypes = debounce(
  dispatchGetResultProductTypes,
  300,
);
const debounceDispatchGetResultSampleNames = debounce(
  dispatchGetResultSampleNames,
  300,
);
const debounceDispatchGetAllTestTypes = debounce(dispatchGetAllTestTypes, 300);
const debounceDispatchGetResultParentCompanies = debounce(
  dispatchGetAdminParentCompanies,
  300,
);
const debounceDispatchGetResultChildCompanies = debounce(
  dispatchGetAdminChildCompanies,
  300,
);
@Component({
  components: {
    AutocompletePaginated,
    NavBar,
    NavDrawer,
    AutocompletePaginatedObjects,
  },
})
export default class Dashboard extends Vue {
  public headers = [
    {
      text: "Report Date",
      sortable: true,
      value: "date_reported",
      align: "left",
    },
    {
      text: "Sample",
      sortable: true,
      value: "sample_name",
      align: "left",
    },
    {
      text: "Batch ID",
      sortable: true,
      value: "batch_id",
      align: "left",
    },
    {
      text: "Order ID",
      sortable: true,
      value: "order_id",
      align: "left",
    },
    {
      text: "Test Type",
      sortable: true,
      value: "test_type.test_name",
      align: "left",
    },
    {
      text: "Test ID",
      sortable: true,
      value: "test_id",
      align: "left",
    },
  ];

  public headersAdmin = [
    {
      text: "Product Type",
      sortable: true,
      value: "sample_type",
      align: "left",
    },
    {
      text: "Sample ID",
      sortable: true,
      value: "sample_id",
      align: "left",
    },
  ];

  loading = false;
  testTypeId: string | null = null;
  companyId: string | null = null;
  parentCompanyId: string | null = null;
  companyInfo: ICompanyInfo | null = null;
  menu = false;

  get results() {
    return readResults(this.$store);
  }

  get resultsPagination() {
    return readResultsPagination(this.$store);
  }

  get totalResults() {
    return this.resultsPagination?.total;
  }

  get userParentCompanies() {
    return readParentCompanies(this.$store);
  }

  get adminParentCompanies() {
    return readAdminParentCompanies(this.$store);
  }

  get adminParentCompaniesPagination() {
    return readAdminParentCompaniesPagination(this.$store);
  }

  get options() {
    return readOptions(this.$store);
  }

  set options(val) {
    commitSetOptions(this.$store, val);
  }

  get orderId() {
    return readOrderId(this.$store);
  }

  set orderId(val: string | null) {
    commitSetOrderId(this.$store, val);
  }

  get sampleId() {
    return readSampleId(this.$store);
  }

  set sampleId(val: string | null) {
    commitSetSampleId(this.$store, val);
  }

  get batchId() {
    return readBatchId(this.$store);
  }

  set batchId(val: string | null) {
    commitSetBatchId(this.$store, val);
  }

  get testId() {
    return readTestId(this.$store);
  }

  set testId(val: string | null) {
    commitSetTestId(this.$store, val);
  }

  get testIds() {
    return readTestIds(this.$store);
  }

  get testIdsPagination() {
    return readTestIdsPagination(this.$store);
  }

  get testType() {
    return readTestType(this.$store);
  }

  set testType(val: ITestType | null) {
    commitSetTestType(this.$store, val);
  }

  get testTypes() {
    return readTestTypes(this.$store);
  }

  get testTypesPagination() {
    return readTestTypesPagination(this.$store);
  }

  get sampleIds() {
    return readSampleIds(this.$store);
  }

  get sampleIdsPagination() {
    return readSampleIdsPagination(this.$store);
  }

  get orderIds() {
    return readOrderIds(this.$store);
  }

  get orderIdsPagination() {
    return readOrderIdsPagination(this.$store);
  }

  get batchIds() {
    return readBatchIds(this.$store);
  }

  get batchIdsPagination() {
    return readBatchIdsPagination(this.$store);
  }

  get productType() {
    return readProductType(this.$store);
  }

  set productType(val: string | null) {
    commitSetProductType(this.$store, val);
  }

  get productTypes() {
    return readProductTypes(this.$store);
  }

  get productTypesPagination() {
    return readProductTypesPagination(this.$store);
  }

  get reportedDates() {
    return readReportedDates(this.$store);
  }

  set reportedDates(val: string[]) {
    if (!val) {
      val = [];
    }
    if (val.length > 1 && Date.parse(val[0]) > Date.parse(val[1])) {
      val = [val[1], val[0]];
    }
    commitSetReportedDates(this.$store, val);
  }

  get sampleName() {
    return readSampleName(this.$store);
  }

  set sampleName(val: string | null) {
    commitSetSampleName(this.$store, val);
  }

  get sampleNames() {
    return readSampleNames(this.$store);
  }

  get sampleNamesPagination() {
    return readSampleNamesPagination(this.$store);
  }

  get showAdminView() {
    return readShowAdminView(this.$store);
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get tableHeaders() {
    return this.showAdminView ? this.headers.concat(this.headersAdmin) : this.headers;
  }

  get parentCompany() {
    return readAdminParentCompany(this.$store);
  }

  set parentCompany(val: IParentCompany | null) {
    if (this.childCompany) {
      this.childCompany = null;
    }
    commitSetAdminParentCompany(this.$store, val);
    this.parentCompanyId = val ? val.id.toString() : null;
  }

  get childCompany() {
    return readAdminChildCompany(this.$store);
  }

  set childCompany(val: ICompany | null) {
    commitSetAdminChildCompany(this.$store, val);
    this.companyId = val ? val.id.toString() : null;
  }

  get adminChildCompanies() {
    return readAdminChildCompanies(this.$store);
  }

  get adminChildCompaniesPagination() {
    return readAdminChildCompaniesPagination(this.$store);
  }

  @Watch("options")
  async onOptionsChange() {
    this.refreshData(false);
  }

  @Watch("testId")
  async onTestIdChange() {
    this.refreshData();
  }

  @Watch("sampleId")
  async onSampleIdChange() {
    this.refreshData();
  }

  @Watch("orderId")
  async onOrderIdChange() {
    this.refreshData();
  }

  @Watch("batchId")
  async onBatchIdChange() {
    this.refreshData();
  }

  @Watch("productType")
  async onProductTypeChange() {
    this.refreshData();
  }

  @Watch("reportedDates")
  async onReportedDatesChange() {
    this.refreshData();
  }

  @Watch("sampleName")
  async onSampleNameChange() {
    this.refreshData();
  }

  @Watch("testType")
  async onTestTypeChange() {
    this.refreshData();
  }

  @Watch("parentCompany")
  async onParentCompanyChange() {
    this.refreshData();
  }

  @Watch("childCompany")
  async onChildCompanyChange() {
    this.refreshData();
  }

  @Watch("showAdminView")
  async onShowAdminView() {
    if (this.showAdminView && this.$route.params.companyType !== "a") {
      this.$router.replace({
        name: "dashboard",
        params: { companyType: "a", id: "all" },
      });
    }
    this.parentCompany = null;
    this.childCompany = null;
    this.refreshData();
  }

  async updateCompanyInfo() {
    if (this.sampleId || this.testId || this.sampleName || this.orderId) {
      this.companyInfo = null;
      this.companyInfo =
        (await dispatchGetCompanyInfo(this.$store, {
          sample_name: this.sampleName || undefined,
          sample_id: this.sampleId || undefined,
          test_id: this.testId || undefined,
          order_id: this.orderId || undefined,
        })) ?? null;
    } else {
      this.companyInfo = null;
    }
  }

  async refreshData(resetPage = true) {
    this.loading = true;
    if (resetPage) {
      this.options.page = 1;
    }
    const promise1 = debouncedDispatchGetResults(this.$store, {
      options: this.options,
      order_id: this.orderId,
      sample_id: this.sampleId,
      batch_id: this.batchId,
      test_id: this.testId,
      company_id: this.companyId,
      parent_company_id: this.parentCompanyId,
      product_type: this.productType,
      reported_date_start: this.reportedDates.length
        ? Date.parse(this.reportedDates[0])
        : null,
      reported_date_end: this.reportedDates.length
        ? Date.parse(this.reportedDates[1])
        : null,
      sample_name: this.sampleName,
      test_type_id: this.testType?.id,
      show_all_companies: this.showAdminView && this.isAdmin ? true : false,
    });
    const promise2 = this.updateCompanyInfo();
    await Promise.all([promise1, promise2]);
    this.loading = false;
  }

  readPath(route) {
    if (route.params.companyType === "p") {
      this.companyId = null;
      this.parentCompanyId = route.params.id || null;
    } else if (route.params.companyType === "c") {
      this.companyId = route.params.id || null;
      this.parentCompanyId = null;
    } else {
      this.companyId = null;
      this.parentCompanyId = null;
    }
  }

  handleRowClick(row) {
    this.$router.push({ name: "result", params: { id: row.id } });
  }

  handleSampleClick(item) {
    this.$router.push({
      name: "sample-landing-page",
      params: { sampleId: item.sample_id },
    });
  }

  public async mounted() {
    await dispatchGetParentCompanies(this.$store);
    this.readPath(this.$route);

    if (this.showAdminView && this.isAdmin) {
      this.parentCompanyId = this.parentCompany?.id?.toString() ?? null;
      this.companyId = this.childCompany?.id?.toString() ?? null;
    }

    await this.refreshData(false);
  }

  public async searchTestIds(searchParams) {
    await debounceDispatchGetResultTestIds(this.$store, searchParams);
  }

  public async searchSampleIds(searchParams) {
    await debounceDispatchGetResultSampleIds(this.$store, searchParams);
  }

  public async searchOrderIds(searchParams) {
    await debounceDispatchGetResultOrderIds(this.$store, searchParams);
  }

  public async searchBatchIds(searchParams) {
    await debounceDispatchGetResultBatchIds(this.$store, searchParams);
  }

  public async searchProductTypes(searchParams) {
    await debounceDispatchGetResultProductTypes(this.$store, searchParams);
  }

  public async searchSampleNames(searchParams) {
    await debounceDispatchGetResultSampleNames(this.$store, searchParams);
  }

  public async searchTestTypes(searchParams) {
    await debounceDispatchGetAllTestTypes(this.$store, searchParams);
  }

  public async searchParentCompanies(searchParams) {
    await debounceDispatchGetResultParentCompanies(this.$store, searchParams);
  }

  public async searchChildCompanies() {
    await debounceDispatchGetResultChildCompanies(this.$store, {
      parent_company_id: this.parentCompanyId,
    });
  }

  public beforeRouteUpdate(to, _from, next) {
    this.readPath(to);
    next();
    this.refreshData();
  }

  public formatDate(dateString: string) {
    return formatDate(dateString, "+00:00");
  }
}
