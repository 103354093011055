























import { Component, Vue, Prop, VModel, Emit, Watch } from "vue-property-decorator";
import { IPagination, IBaseObject } from "@/interfaces";

const AUTOCOMPLETE_LIMIT = 10;

@Component
export default class AutocompletePaginatedObjects extends Vue {
  @VModel({ type: Object, default: () => undefined })
  selected?: IBaseObject | undefined;
  query: string | null = null;
  loading = false;
  @Prop({ type: String, default: "" })
  label!: string;
  @Prop({ default: () => [] })
  items!: IBaseObject[];
  @Prop({ default: () => ({ total: 0, offset: 0 }) })
  itemsPagination!: IPagination;
  @Prop({ type: String, default: "" })
  itemText!: string;
  @Prop({ type: String, default: "id" })
  itemValue!: string;
  @Prop({ type: Boolean, default: true })
  returnObject!: boolean;

  @Emit("query-change")
  public async search(newQuery: string | null) {
    this.loading = true;
    return { query: newQuery, limit: AUTOCOMPLETE_LIMIT, skip: 0 };
  }

  @Watch("query")
  async onQueryChange(newQuery: string | null) {
    if (this.query !== null) {
      await this.search(newQuery);
    }
  }

  @Watch("items")
  async itemsUpdated() {
    // Not the best solution here, but because we are using v-intersect for pagination,
    // when a new query comes in it triggers that v-intersect callback (which we don't
    // want at that point) which messes up the search (search runs and paginate runs
    // immediately after it with the previous pagination offset, which is where things get wonky).
    // Here we make sure search isn't considered finished until the Vuex store testIds
    // are updated, so that the paginate method sees loading is still true and doesnt
    // run in the aforementioned scenario
    this.loading = false;
  }

  onFocus() {
    this.search(this.query);
  }

  onClear() {
    this.search("");
  }

  public async paginate() {
    const resultsLength = this.items?.length || 0;
    if (
      resultsLength > 0 &&
      resultsLength < this.itemsPagination.total &&
      !this.loading
    ) {
      this.loading = true;
      this.$emit("paginate", {
        query: this.query,
        limit: AUTOCOMPLETE_LIMIT,
        skip: (this.itemsPagination?.offset || 0) + AUTOCOMPLETE_LIMIT,
      });
    }
  }

  public async onIntersect(_entries, _observer, isIntersecting) {
    if (isIntersecting) {
      this.paginate();
    }
  }
}
